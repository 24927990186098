import { faker } from '@faker-js/faker'
import type { CreateLearningSession, CreateLearningSessionFormData, LearningSession, LearningSessionDetails, Question, Topic, Year } from '@t16t/sdk'
import { sleep } from '../../utils/general';

type ApiResponse<T> = {
    errorMessage: string | undefined;
    data: T | undefined;
}

export class FakeLearningSessionService {

    async fetchLearningSessions(): Promise<ApiResponse<LearningSession[]>> {
      await sleep(1000);
      const chanceOfFailure = Math.round(Math.random()) === 1;
      const data = chanceOfFailure? undefined : this.generateFakeLearningSessions(10)
        return {
            errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
            data
        };
    }

    async fetchLearningSession(): Promise<ApiResponse<LearningSessionDetails>> {
      await sleep(1000);
      const chanceOfFailure = Math.round(Math.random()) === 1;
      const data = chanceOfFailure? undefined : this.generateFakeLearningSession()
        return {
            errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
            data
        }
    }

    async createLearningSession(data: CreateLearningSession): Promise<ApiResponse<void>> {
      await sleep(500);
        return {
            errorMessage: undefined,
            data: undefined
        }
    }

    async fetchCreateLearningSessionFormData(): Promise<ApiResponse<CreateLearningSessionFormData>> {
      await sleep(500);
      const chanceOfFailure = Math.round(Math.random()) === 1;
      const data = chanceOfFailure? undefined : this.generateFakeCreateLearningSessionFormData();
      return {
        errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
        data
      }
    }

    async startLearningSession(): Promise<ApiResponse<void>> {
      await sleep(250);
        return {
            errorMessage: undefined,
            data: undefined
        }
    }

    async submitLearningSession(): Promise<ApiResponse<void>> {
      await sleep(250);
      const chanceOfFailure = Math.round(Math.random()) === 1;
        return {
            errorMessage: chanceOfFailure? 'Something went wrong': undefined,
            data: undefined
        }
    }

    async finishLearningSession(): Promise<ApiResponse<void>> {
      await sleep(250);
      const chanceOfFailure = Math.round(Math.random()) === 1;
        return {
            errorMessage: chanceOfFailure? 'Something went wrong' : undefined,
            data: undefined
        }
    }

    private generateFakeLearningSessions(count: number = 10): LearningSession[] {
        return faker.helpers.multiple((): LearningSession => {
          const numberOfRequestedQuestions = faker.number.int({ min: 1, max: 40 })
          return {
            entityId: faker.string.uuid(),
            type: faker.helpers.arrayElement(['STUDY_MODE', 'EXAM_MODE']),
            createdAt: faker.date.recent({ days: 5 }).toLocaleDateString(),
            updatedAt: faker.date.recent().toLocaleDateString(),
            numberOfRequestedQuestions,
            numberOfProvidedQuestions: faker.number.int({ min: 1, max: numberOfRequestedQuestions }),
            status: faker.helpers.arrayElement(['ONGOING', 'FINISHED', 'NOT_STARTED']),
            subjects: faker.helpers.multiple(() => faker.word.noun(), { count: 1 }),
            topics: faker.helpers.multiple(() => faker.word.noun(), { count: 3 }),
          }
        }, { count: { min: 0, max: count } })
      }


      private generateFakeLearningSession(count: number = 10): LearningSessionDetails {
        const numberOfRequestedQuestions = faker.number.int({ min: 1, max: 40 });
        const topics = faker.helpers.multiple(() => faker.word.noun(), { count: 3 });
        return {
          entityId: faker.string.uuid(),
          type: faker.helpers.arrayElement(['STUDY_MODE', 'EXAM_MODE']),
          questions: faker.helpers.multiple((): Question => {
            return {
              entityId: faker.string.uuid(),
              questionEntityId: faker.string.uuid(),
              selectedAnswerEntityId: faker.string.uuid(),
              type: faker.helpers.arrayElement(['PLAIN_TEXT', 'RICH_TEXT']),
              identifierCode: faker.vehicle.vrm(),
              reference: faker.word.words(),
              frequency: faker.number.int( { min: 1, max: 10 }),
              mainQuestionEntityId: faker.helpers.arrayElement([faker.string.uuid(), null]),
              subquestions: faker.helpers.multiple(() => faker.string.uuid(), { count: { min: 0, max: 3} }),
              periods: faker.helpers.multiple(() =>  {
                return faker.date.between({ from: '2012', to: '2024' }).getFullYear().toString() + '-' + faker.word.noun()
              }
              , { count: { min: 1, max: 12 } }),
              topics: faker.helpers.arrayElements(topics, { min: 1, max: topics.length}),
              data: {
                plainText: {
                  content: faker.lorem.paragraph(),
                },
                richText: {
                  content: faker.lorem.text(),
                },
              },
              answers: faker.helpers.multiple(() => {
                return {
                  entityId: faker.string.uuid(),
                  type: faker.helpers.arrayElement(['PLAIN_TEXT', 'RICH_TEXT']),
                  isCorrect: faker.datatype.boolean(),
                  data: {
                    type: faker.helpers.arrayElement(['PLAIN_TEXT', 'RICH_TEXT']),
                    plainText: {
                      content: faker.lorem.paragraph(),
                    },
                    richText: {
                      content: faker.lorem.text(),
                    },
                  },
                  explanation: {
                    type: faker.helpers.arrayElement(['PLAIN_TEXT', 'RICH_TEXT']),
                    plainText: {
                      content: faker.lorem.paragraph(),
                    },
                    richText: {
                      content: faker.lorem.text(),
                    },
      
                  },
                }
              }, { count: { min: 2, max: 5 } }),
              overallAnswersExplanation: {
                type: faker.helpers.arrayElement(['PLAIN_TEXT']),
                plainText: {
                  content: faker.lorem.paragraph(),
                },
                richText: {
                  content: faker.lorem.text(),
                },
              },
            }
          }, { count: { min: 0, max: count } }),
          createdAt: faker.date.recent().toLocaleDateString(),
          updatedAt: faker.date.recent().toLocaleDateString(),
          numberOfRequestedQuestions,
          numberOfProvidedQuestions: faker.number.int({ min: 1, max: numberOfRequestedQuestions }),
          status: faker.helpers.arrayElement(['ONGOING', 'FINISHED']),
          subjects: faker.helpers.multiple(() => faker.word.noun(), { count: 1 }),
          topics: topics,
        }
      }

      private generateFakeCreateLearningSessionFormData(count: number= 10): CreateLearningSessionFormData {
        return {
          subjects: faker.helpers.multiple(() => {
            return {
              entityId: faker.string.uuid(),
              name: faker.word.noun(),
              topics: faker.helpers.multiple((): Topic => {
                return {
                  entityId: faker.string.uuid(),
                  name: faker.word.noun(),
                  periods: faker.helpers.multiple((): Year => {
                    return {
                      label: faker.number.int({ min: 2010, max: 2024 }).toString(),
                      questionCount: faker.number.int({ min: 20, max: 100 }),
                      availableQuestionsCount: faker.number.int({ min: 20, max: 100 }),
                      totalQuestionsCount: faker.number.int({ min: 20, max: 100 }),
                      isAvailable: faker.datatype.boolean(),
                    }
                  }),
                }
              }, { count: { min: 3, max: 6}}),
            }
          }, { count: { min: 0, max: count } }),
        }
      }
}